import React, { useEffect, useState } from 'react';
import * as cheerio from 'cheerio';
import bannerImgUrl from './pages/assets/supersoulbanner.webp';

type WindowDimensionType = {
  innerWidth: number;
  outerWidth: number;
  innerHeight: number;
  outerHeight: number;
};

export function useWindowDimension() {
  const [dim, setDim] = React.useState<WindowDimensionType>();

  React.useEffect(() => {
    const windowResizeListener = () => {
      const { innerHeight, innerWidth, outerHeight, outerWidth } = window;
      setDim(() => ({ innerHeight, innerWidth, outerHeight, outerWidth }));
    };

    windowResizeListener();
    window.addEventListener(`resize`, windowResizeListener);

    return () => {
      window.removeEventListener(`resize`, windowResizeListener);
    };
  }, []);

  return dim;
}

function parseCookie(doc: Document) {
  const initValue: { [key: string]: string } = {};

  if (!doc.cookie?.trim()) return {};

  return doc.cookie
    .split(`;`)
    .map((chunk) => chunk.split(`=`))
    .reduce((acc, curr) => {
      acc[decodeURIComponent(curr[0].trim())] = decodeURIComponent(
        curr[1].trim(),
      );
      return acc;
    }, initValue);
}

export function useCookie() {
  const [cookies, setCookies] = React.useState<{ [key: string]: string }>();

  React.useEffect(() => {
    setCookies(parseCookie(document));
  }, []);

  return {
    ...cookies,

    hasConsent: cookies?.consent === `YES`,

    setConsent(consent: 'YES' | 'NO') {
      const cookieObj = parseCookie(document);
      const cookieExpiresAt = new Date();

      cookieExpiresAt.setFullYear(cookieExpiresAt.getFullYear() + 100);

      Object.defineProperty(cookieObj, `consent`, {
        enumerable: true,
        value: consent,
      });

      for (const cookieName of Object.keys(cookieObj)) {
        document.cookie = `${cookieName}=${
          cookieObj[cookieName]
        };expires=${cookieExpiresAt.toUTCString()}; path=/`;
      }

      setCookies(parseCookie(document));
    },

    removeConsent() {
      const cookieExpiresAt = new Date();

      cookieExpiresAt.setFullYear(cookieExpiresAt.getFullYear() - 100);
      document.cookie = `consent=;expires=${cookieExpiresAt.toUTCString()}; path=/`;
      setCookies(parseCookie(document));
    },
  };
}

export function useControlFloatingButton() {
  const [isHidden, setIsHidden] = React.useState(true);

  React.useEffect(() => {
    const timeOut = 2000;
    let timeoutListener = setTimeout(() => setIsHidden(() => true), timeOut);

    const eventListener = () => {
      clearTimeout(timeoutListener);
      timeoutListener = setTimeout(() => setIsHidden(() => true), timeOut);

      const scrollTop =
        window.pageYOffset !== undefined
          ? window.pageYOffset
          : (
              document.documentElement ||
              document.body.parentNode ||
              document.body
            ).scrollTop;

      const _isHidden =
        window.document.body.scrollHeight - (window.innerHeight + 500) <
        scrollTop
          ? true
          : scrollTop > window.innerHeight
          ? false
          : true;

      setIsHidden(() => _isHidden);
    };

    window.addEventListener(`scroll`, eventListener);

    return () => {
      clearTimeout(timeoutListener);
      window.removeEventListener(`scroll`, eventListener);
    };
  }, []);

  return { isHidden };
}

export function useMobileResponsive() {
  const [isMobile, setIsMobile] = React.useState(false);
  const [windowWidth, setWindowWidth] = React.useState(0);

  React.useEffect(() => {
    setWindowWidth(() => window.innerWidth);

    if (windowWidth <= 768) {
      setIsMobile(() => true);
    }
    if (windowWidth >= 768) {
      setIsMobile(() => false);
    }

    window.addEventListener(`resize`, () =>
      setWindowWidth(() => window.innerWidth),
    );
  }, [windowWidth, isMobile]);

  return { isMobile, windowWidth };
}

export const useScroll = (boundStart: number, boundEnd: number) => {
  const [insideBounds, setInsideBounds] = useState(false);
  const [windowHeight, setWindowHeight] = useState(0);

  useEffect(() => {
    const handleScroll = () => {
      setWindowHeight(window.scrollY);

      if (window.scrollY > boundStart && window.scrollY < boundEnd) {
        setInsideBounds(true);
      } else {
        setInsideBounds(false);
      }
    };

    // Add Scroll Event listener
    window.addEventListener(`scroll`, handleScroll);
    return () => window.removeEventListener(`scroll`, handleScroll);
  }, []);

  return { insideBounds, windowHeight };
};

type Nullable<T> = T | null;

const buttonTexts = [
  `Try for Free `,
  `Download for Free `,
  `Unlock 7-day Free Premium`,
  `Get now for Free`,
  `Experience Now`,
  `Unlock Full Access Now `,
  `Unlock Premium for Free`,
];

const superSoulLink = `https://store.religione.ai/?utm_source=blogBanner&utm_medium=blog&utm_campaign=websitePage`;

const topTenBlogs = [
  `64ea326db1d6a7c26e54783b`,
  `6511697bb1d6a7c26e54887d`,
  `66ac777bcd768c0af257f3ee`,
  `65046357b1d6a7c26e54830b`,
  `65a28496cd768c0af257b5e6`,
  `64dc99d2b1d6a7c26e546eb0`,
  `64ee1393b1d6a7c26e547910`,
  `650c43ecb1d6a7c26e54862e`,
  `674af03dcdf9f5c8abca3d5c`,
  `64cce434b1d6a7c26e546b1a`,
];

export const useCheerioModification = (
  htmlString:
    | cheerio.AnyNode
    | cheerio.AnyNode[]
    | Buffer
    | Nullable<string>
    | undefined,
  link: string,
  selectedLanguage: string | string[],
  referrer: string,
  blogId: string,
) => {
  const [modifiedHtml, setModifiedHtml] = useState(``);

  // useEffect(() => {
  //   console.log(modifiedHtml);
  // }, [modifiedHtml]);

  useEffect(() => {
    if (typeof htmlString === `string`) {
      const $ = cheerio.load(htmlString);

      const baseURL = `https://mind.level.game/share/`;

      // Add 'nofollow' to appropriate links
      $(`a`).each((_, el) => {
        const href = $(el).attr(`href`);
        if (href && href.includes(baseURL)) {
          $(el).attr(`rel`, `nofollow`);
        }
      });

      // If the referrer is not 'levelapp', add buttons and banner
      if (referrer !== `levelapp` && referrer !== `levelapppremium`) {
        let buttonCount = 0;
        let bannerAdded = false;

        $(`h2`).each((_, ele) => {
          const buttonText = buttonTexts[buttonCount % buttonTexts.length];
          const button = `
            <a href="${link}" style="color: inherit; text-decoration: none;">
              <button
                style="
                  background: linear-gradient(to right, #44199A, #9D77EE);
                  color: white;
                  font-size: 0.98rem;
                  font-weight: 800;
                  padding: 0.75rem 1.25rem;
                  margin: 0.75rem 0;
                  border-radius: 1.5rem;
                  height: 3.5rem;
                  transition: all 0.5s;
                  display: inline-block;
                  transform-origin: center;
                "
                onmouseover="this.style.background='linear-gradient(to right, #9D77EE, #9D77EE, #44199A)'; this.style.transform='scale(1.05)';"
                onmouseout="this.style.background='linear-gradient(to right, #44199A, #9D77EE, #9D77EE)'; this.style.transform='scale(1)';"
              >
                ${buttonText}
              </button>
            </a>
          `;
          $(ele).before(button);

          // Add banner after the second button
          if (
            topTenBlogs?.includes(blogId) &&
            buttonCount === 3 &&
            !bannerAdded
          ) {
            const banner = `
            <a href="${superSoulLink}" target="_blank" style="display: block; 
              transition: transform 0.3s ease;"
              onmouseover="this.style.transform='scale(1.02)'"
              onmouseout="this.style.transform='scale(1)'">

              <div style="overflow: hidden; border-radius: 1rem;">
                <img 
                  src="${bannerImgUrl.src}" 
                  alt="SuperSoul Store Banner" 
                  style="width: 100%; height: 100%; object-fit: cover; margin-top:0; margin-bottom:0;" />
              </div>
            </a>

          <!-- Image Note -->
          <p style="font-size: 0.9rem; color: #666; margin-top: 0.5rem; margin-bottom: 1rem;">
            Discover our collection of authentic spiritual bracelets for wealth, love, career success, and health. Handcrafted with genuine crystals and gemstones.
          </p>
          `;
            $(ele).after(banner);
            bannerAdded = true;
          }

          buttonCount++;
        });
      }

      const updatedHtmlString = $.html();
      setModifiedHtml(updatedHtmlString);
    }
  }, [htmlString, link, selectedLanguage, referrer, modifiedHtml]);

  return { modifiedHtml };
};

export function useScrollSpy(
  ids: any[],
  options: IntersectionObserverInit | undefined,
) {
  const [activeId, setActiveId] = React.useState<string | undefined>();

  const observer = React.useRef<IntersectionObserver | undefined>();
  React.useEffect(() => {
    const elements = ids.map((id) => document.getElementById(id));
    observer.current?.disconnect();
    observer.current = new IntersectionObserver((entries) => {
      entries.forEach((entry) => {
        if (entry?.isIntersecting) {
          setActiveId(entry.target.id);
        }
      });
    }, options);
    elements.forEach((el) => {
      if (el) {
        observer.current?.observe(el);
      }
    });
    return () => observer.current?.disconnect();
  }, [ids, options]);
  return activeId;
}

export function useUserLocation() {
  const [isIndia, setIsIndia] = useState(true);
  const [isLoading, setIsLoading] = useState(true);
  const [metaPixel, setMetaPixel] = useState(``);
  const indiaPixel = `500207002676577`;
  const internationalPixel = `798889752405987`;

  useEffect(() => {
    const fetchLocation = async () => {
      try {
        const localData = localStorage.getItem(`isIndia`);

        if (localData === `true`) {
          setIsIndia(true);
          setIsLoading(false);
          return;
        }

        const response = await fetch(
          `https://ipapi.co/json/?key=czbKS4is809Mnyqf0CLNOpPj5erMWVhbfoIrFHAfWgHMMpnpKG`,
        );

        const jsonData = await response.json();

        if (jsonData.country && jsonData.country !== `IN`) {
          setIsIndia(false);
          localStorage.setItem(`isIndia`, `false`);
        } else {
          setIsIndia(true);

          localStorage.setItem(`isIndia`, `true`);
        }
      } catch (error) {
        console.error(`Error fetching location:`, error);
        setIsIndia(true);
      } finally {
        setIsLoading(false);
      }
    };

    fetchLocation();
  }, []);

  return { isIndia, isLoading, metaPixel };
}

export function useApiLoading() {
  const [isIndia, setIsIndia] = useState(true);
  const [isLoading, setIsLoading] = useState(true);
  const [metaPixel, setMetaPixel] = useState(``);
  const indiaPixel = `500207002676577`;
  const internationalPixel = `798889752405987`;

  useEffect(() => {
    fetch(
      `https://ipapi.co/json/?key=czbKS4is809Mnyqf0CLNOpPj5erMWVhbfoIrFHAfWgHMMpnpKG`,
    )
      .then(function (response) {
        response.json().then((jsonData) => {
          // console.log(jsonData);

          if (jsonData.country != `IN`) {
            setIsIndia(false);
            setIsLoading(false);
            setMetaPixel(internationalPixel);
          } else {
            setIsIndia(true);
            setMetaPixel(indiaPixel);
            setIsLoading(false);
          }
        });
      })
      .catch(function (error) {
        console.log(error);
      });
  }, []);

  return { isIndia, isLoading, metaPixel };
}
