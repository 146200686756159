// export const FB_PIXEL_ID = `1277196556103339`;
// export const FB_PIXEL_ID = `500207002676577`;

export const pageview = () => {
  if (
    typeof window !== `undefined` &&
    typeof (window as any).fbq === `function`
  ) {
    (window as any).fbq(`track`, `PageView`);
  } else {
    console.warn(`Facebook Pixel not loaded`);
  }
};

// https://developers.facebook.com/docs/facebook-pixel/advanced/
export const event = (name: string, options = {}) => {
  if (
    typeof window !== `undefined` &&
    typeof (window as any).fbq === `function`
  ) {
    (window as any).fbq(`track`, name, options);
  } else {
    console.warn(`Facebook Pixel not loaded, event '${name}' not tracked`);
  }
};
